import React from "react"
import YouTube from "react-youtube"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action"
import { MobilityMeta } from "../data/seo-meta"

import group from "../images/mobility/mobility_group_of_people.svg"
import appSm from "../images/mobility/slice_app_sm.png"
import appLg from "../images/mobility/slice_app_lg.png"
import whitepaper from "../images/mobility/whitepaper.png"
import carSm from "../images/mobility/car_driving_sm.svg"
import carLg from "../images/mobility/car_driving_lg.svg"

const youtubeOpts = {
  playerVars: {
    modestbranding: 1,
    rel: 0,
  },
}

const technologyPoints = [
  {
    title: "In‑vehicle Device + Next Generation Mobile App",
    content:
      "An end‑to‑end solution designed to maximize safety on the road and a positive customer experience.",
  },
  {
    title: "Real‑time Alerts",
    content:
      "Gentle nudges to drivers when they exhibit unsafe behavior or when environmental factors (like bad weather) pose a risk.",
  },
  {
    title: "Driver Education and Rewards",
    content:
      "Drivers receive personalized coaching and positive reinforcement, steering them to better decisions on the road.",
  },
  {
    title: "Advanced Contextual Telematics",
    content:
      "Provide a complete picture of the driver’s risk on the road and open the door for a dynamic insurance offering.",
  },
]

const MobilityPage = () => {
  return (
    <Layout>
      <SEO
        description={MobilityMeta.description}
        path={MobilityMeta.path}
        title={MobilityMeta.title}
      />

      <div className="container py-20 md:py-24">
        <h1 className="h1 mb-8 md:text-center">
          Driven by data, with a passion for protection.
        </h1>
        <p className="p1 md:max-w-[98rem] md:mx-auto md:text-center">
          When you have data that learns and responds to individual drivers and
          environments, you have Next Generation Mobility Insurance.
          <br />
          <br />
          <CallToAction label="Schedule a demo" to="/contact/" />
        </p>

        <YouTube
          containerClassName="-mx-8 mt-20 video"
          videoId="mNBBgJ6D7IY"
          title="Slice Mobility video"
          opts={youtubeOpts}
        />

        <h2 className="h2 pt-12 mb-8 md:pt-32">
          Paving the road: A partnership that protects drivers in a fast and
          furious future.
        </h2>
        <div className="flex flex-wrap mb-12">
          <p className="p1 basis-full mb-8 md:basis-1/2 md:pr-24">
            We partnered with a global automotive components manufacturer to
            create the future of mobility insurance. With AI, machine learning,
            and all available data, we leverage an integrated ecosystem of
            information that translates to not only more relevant and responsive
            protection, but ultimately helps drivers be safer on the road.
          </p>
          <img
            className="mx-auto basis-full md:basis-1/2 md:max-w-1/2"
            src={group}
            alt={"Group of people"}
          />
        </div>

        <div className="flex flex-wrap pt-6 md:pt-40 items-center">
          <picture className="flex justify-center basis-full mb-8 md:basis-1/2">
            <source media="(min-width:768px)" srcSet={appLg} />
            <img src={appSm} alt="Slice mobile application" />
          </picture>
          <div className="basis-full md:basis-1/2 md:pl-24">
            <h2 className="h2 mb-4 md:mb-16">
              Insurance redefined by technology.
            </h2>
            <p className="p1 mb-16">
              Rich data provides accurate representations of drivers and the
              vehicles they operate, allowing you to reach and connect with
              customers in a way that hasn’t been possible until now.
            </p>
            {technologyPoints.map((point) => (
              <div key={point.title}>
                <h3 className="h4">{point.title}</h3>
                <p className="p2 mb-8">{point.content}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-wrap mt-56 bg-[#f8f8f8] items-center -mx-8 md:mx-32 md:rounded-3xl md:flex-nowrap p-8 pb-16 md:pl-32 md:py-16">
          <div className="order-2 basis-full md:order-1 md:basis-3/4">
            <h2 className="h2">Curious for more?</h2>
            <CallToAction
              label="Download white paper"
              to="https://info.sliceinsurance.com/en-us/the-future-of-mobility-white-paper-download"
              external={true}
            />
          </div>
          <div className="order-1 basis-full relative md:order-2 md:w-80 md:basis-1/4">
            <img
              className="-mt-56 mx-auto md:absolute md:mt-0 md:-translate-y-1/2"
              src={whitepaper}
              alt="Slice's New Demand for On-Demand white paper"
            />
          </div>
        </div>

        <div className="md:text-center mt-28 mb-12 md:mt-32">
          <picture className="flex justify-center">
            <source media="(min-width:768px)" srcSet={carLg} />
            <img src={carSm} alt="Car driving" />
          </picture>
          <h2 className="h1 my-8 mx-auto max-w-[84rem]">Let’s hit the road!</h2>
          <p className="p1">
            Send us your email and we’ll schedule a demo or time to connect.
            <br />
            <br />
            <CallToAction label="Connect with us" to="/contact/" />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default MobilityPage

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import rightArrow from "../images/common/right_arrow.svg"

const CallToAction = ({ label, to, external, className }) => {
  const linkClass = `cta border-b-[3px] border-b-[#D91451] pb-2 inline-block ${className}`
  const content = (
    <>
      {label}
      <img
        className="inline-block w-8 -translate-y-1 ml-2"
        src={rightArrow}
        alt="right arrow"
      />
    </>
  )
  return (
    <>
      {external ? (
        <a
          href={to}
          rel="noreferrer nofollow"
          className={linkClass}
          target="_blank"
        >
          {content}
        </a>
      ) : (
        <Link to={to} className={linkClass}>
          {content}
        </Link>
      )}
    </>
  )
}

CallToAction.defaultProps = {
  external: false,
}

CallToAction.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  className: PropTypes.string,
}

export default CallToAction
